export default {
    init(vue) {
        return {
            alipayManagerOrdersSynctrade(data) {
                return vue.$http({
                    method: 'PUT',
                    url: `/api/v1/alipay/manager/orders/synctrade`,
                    data,
                })
            },
            wechatpayOrdersSynctrade(data) {
                return vue.$http({
                    method: 'PUT',
                    url: `/api/v1/wechatpay/manager/orders/synctrade`,
                    data,
                })
            },
            alipayManagerPretradesSync(data) {
                return vue.$http({
                    method: 'PUT',
                    url: `/api/v1/alipay/manager/pretrades/sync`,
                    data,
                })
            },
            getAlipayManagerPretrades(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/alipay/manager/pretrades',
                    data,
                })
            },
            getAdchannelManagerRules(data) {
                return vue.$http({
                    method: 'GET',
                    url: '/api/v1/adchannel/manager/rules',
                    data,
                })
            },
            getAdchannelManagerRulesReset(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/adchannel/manager/rules/reset',
                    data,
                })
            },
            getManagerRecords(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/customerTel/manager/records',
                    data,
                })
            },
            getrefundFailLogs(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/alipay/manager/refundFailLogs',
                    data,
                })
            },
            getUserComplaints(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/alipay/service/complaints',
                    data,
                })
            },
            getManagerComplaints(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/wechatpay/manager/complaints',
                    data,
                })
            },
            getManagerComplaintsAL(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/alipay/manager/complains',
                    data,
                })
            },
            handleTk(data) {
                return vue.$http({
                    method: 'PUT',
                    url: `/api/v1/wechatpay/manager/complaints/${data.complaint_id}/refund/${data.out_trade_no}`,
                })
            },
            getManagerOrders(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/easyorders/manager/orders',
                    data,
                })
            },
            getManagerTrades(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/wechatpay/manager/trades',
                    data,
                })
            },
            getManagerTradesAL(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/alipay/manager/trades',
                    data,
                })
            },
            getMembers(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/equitymember/manager/members',
                    data,
                })
            },
            getEquityMember(params) {
                return vue.$http({
                    method: 'GET',
                    url: '/api/v1/common/dict',
                    params,
                })
            },
            handleEquityStatus(data) {
                return vue.$http({
                    method: 'PUT',
                    url: `/api/v1/equitymember/manager/members/${data.id}/equity/${data.elogid}`,
                    data,
                })
            },
            handleManagerRetryRecharge(data) {
                return vue.$http({
                    method: 'POST',
                    url: `/api/v1/equitymember/manager/retryRecharge`,
                    data,
                })
            },
            handleOrderStatus(data) {
                return vue.$http({
                    method: 'PUT',
                    url: `/api/v1/easyorders/manager/orders/${data.id}/status`,
                    data,
                })
            },
            deletePlan(id) {
                return vue.$http({
                    method: 'DELETE',
                    url: `/api/v1/admanager/plan/${id}`,
                })
            },
            saveYdTaskItem(data) {
                return vue.$http({
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    url: '/yd/pages/quest/save',
                    data: data
                })
            },
            getYdTransactNumTopList(params) {
                return vue.$http({
                    method: 'GET',
                    url: '/yd/pages/quest/listTop',
                    params: params
                })
            },
            setFillInStandBy(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/alipay/manager/setFillInStandBy',
                    data: data
                })
            },
            getPayAccountRules() {
                return vue.$http({
                    method: 'GET',
                    url: '/api/v1/adchannel/manager/payaccountrules'
                })
            },
            reloadAccountMapping() {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/alipay/manager/reloadAccountMapping'
                })
            },
            getAgreementOrdersAL(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/alipay/manager/agreements',
                    data,
                })
            },
            getAgreementPayOrders(id) {
                return vue.$http({
                    method: 'GET',
                    url: `/api/v1/alipay/manager/agreements/${id}/payorders`,
                })
            },
            getAgreementStatus(id) {
                return vue.$http({
                    method: 'GET',
                    url: `/api/v1/alipay/manager/agreements/${id}/status`,
                })
            },
            agreementPay(id) {
                return vue.$http({
                    method: 'POST',
                    url: `/api/v1/alipay/manager/agreements/${id}/pay`,
                })
            },
            handleCommonOrderStatus(data) {
                return vue.$http({
                    method: 'PUT',
                    url: `/api/v1/commonorder/manager/orders/${data.id}/status`,
                    data,
                })
            },
            getCommonOrders(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/commonorder/manager/orders',
                    data,
                })
            }
        }
    }
}
